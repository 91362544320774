import {apiClient} from "./api-client.js";

export const createAppointment = (values) => apiClient({
    method: 'post',
    url: `/appointments`,
    data: values
});

export const getAppointments = (dateRange) => apiClient({
    method: 'get',
    url: `/appointments?minDate=${dateRange.min}&maxDate=${dateRange.max}`
});

export const updateAppointment = (id, values) => apiClient({
    method: 'put',
    url: `/appointments/${id}`,
    data: values
});

export const deleteAppointment = (id) => apiClient({
    method: 'delete',
    url: `/appointments/${id}`,
});

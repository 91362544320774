import {defineStore} from 'pinia'
import Vue from "vue";
import {validateResponse} from "@/utils";
import {createAppointment, deleteAppointment, getAppointments, updateAppointment} from "@/services/appointment-service";

export const useAppointmentStore = defineStore('appointment', {
    state: () => ({
        loading: false,
        appointments: [],
        createdAppointment: {},
        currentAppointment: {}
    }),
    actions: {
        async createAppointment(values) {
            try {
                this.loading = true
                const response = await createAppointment(values)
                this.createdAppointment = validateResponse(response, 201)
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async getAppointments(dateRange) {
            try {
                this.loading = true
                const response = await getAppointments(dateRange)
                const validatedResponse = validateResponse(response, 200)
                this.appointments = validatedResponse.rows
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async updateAppointment(id, values) {
            try {
                this.loading = true
                if (values.tags.billed) {
                    Vue.$toast.error('Nu poti sterge sau modifica un antrenament facturat.')
                } else {
                    const response = await updateAppointment(id, values)
                    validateResponse(response, 200)
                }
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async deleteAppointment(id, values) {
            try {
                this.loading = true
                if (values.tags.billed) {
                    Vue.$toast.error('Nu poti sterge sau modifica un antrenament facturat.')
                } else {
                    const response = await deleteAppointment(id)
                    validateResponse(response, 204)
                }
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
    }
})

<template>
  <a-row :gutter="[16, 16]" type="flex">
    <a-col :span="20" :order="1"><h2>Calendar</h2></a-col>
    <a-col :span="4" :order="2">
      <AddButton @click="handleShowAddEventModal" style="float: right"/>
      <a-modal v-model:visible="showAddEventModal" title="Adauga antrenament" @ok="handleAddEvent" :destroyOnClsose="true" :afterClose="handleAfterModalClose">
        <a-row>
          <a-col>
            <a-select
                :value="addEventValues.client || undefined"
                show-search
                placeholder="Alege client"
                style="width: 100%; margin-bottom: 20px;"
                :options="clientList"
                :filter-option="filterOption"
                @change="handleClientSelectChange"
            ></a-select>
            <a-date-picker :value="addEventValues.date || undefined" @change="handleChangeWorkoutDate" style="width: 100%; margin-bottom: 20px;" format="DD/MM/YYYY"  placeholder="Data antrenamentului"/>
            <a-row>
              <a-col :span="12">
                <a-time-picker :value="addEventValues.startTime" @change="handleSetStartTime" style="width: 100%" placeholder="Ora de inceput" format="HH:mm" :minuteStep="30" valueFormat="HH:mm"/>
              </a-col>
              <a-col :span="12">
                <a-time-picker :value="addEventValues.endTime" @change="handleSetEndTime" style="width: 100%" placeholder="Ora de sfarsit" format="HH:mm" :minuteStep="30" valueFormat="HH:mm"/>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-modal>
    </a-col>
    <a-col :xs="12" :md="10" :order="this.$isMobile() ? 4 : 3">
      <a-button type="primary" block @click="handlePrevDate">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
          <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
        </svg>
      </a-button>
    </a-col>
    <a-col :xs="12" :md="10" :order="this.$isMobile() ? 5 : 4">
      <a-button type="primary" block @click="handleNextDate">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
          <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
        </svg>
      </a-button>
    </a-col>
    <a-col :xs="24" :md="4" :order="this.$isMobile() ? 3 : 5">
      <a-date-picker v-model:value="selectedDate" @change="handleChangeSelectedDate" style="width: 100%" format="DD/MM/YYYY"/>
    </a-col>
    <a-col :span="24" :order="6" style="display: flex; flex-direction: column">
      <DayPilotCalendar id="dp" :config="config" ref="calendar"/>
    </a-col>
  </a-row>
</template>

<script>
import {DayPilot, DayPilotCalendar, DayPilotNavigator} from '@daypilot/daypilot-lite-vue'
import {useAppointmentStore} from "@/stores/appointment-store";
import moment from 'moment'
import AddButton from "@/components/Buttons/AddButton";
import {useClientStore} from "@/stores/client-store";
import _ from 'lodash'

export default {
  name: 'Calendar',
  setup () {
    const appointmentStore = useAppointmentStore()
    const clientStore = useClientStore()

    return {
      appointmentStore,
      clientStore
    }
  },
  data: function () {
    return {
      clientList: [],
      showAddEventModal: false,
      events: [],
      addEventValues: {
        client: null,
        date: null,
        startTime: null,
        endTime: null,
      },
      selectedDate: moment(),
      config: {
        locale: "ro-ro",
        durationBarVisible: true,
        viewType: this.$isMobile() ? "Day" : "Week",
        headerDateFormat: "ddd - d/MM",
        startDate: new Date(),
        scrollLabelsVisible: true,
        timeRangeSelectedHandling: "Enabled",
        onTimeRangeSelected: async (args) => {
          this.addEventValues.date = moment(args.start.value.split('T')[0]);
          this.addEventValues.startTime = args.start.value.split('T')[1];
          this.addEventValues.endTime = args.end.value.split('T')[1];
          this.showAddEventModal = true
        },
        eventDeleteHandling: "Enabled",
        onEventDelete: async (event) => {
          await this.deleteEvent(event.e.data)
        },
        onEventMoved: async (event) => {
          await this.updateEvent(event.e.data)
        },
        onEventResized: async (event) => {
          await this.updateEvent(event.e.data)
        },
      },
    }
  },
  props: {},
  components: {
    DayPilotCalendar,
    DayPilotNavigator,
    AddButton,
  },
  computed: {
    // DayPilot.Calendar object - https://api.daypilot.org/daypilot-calendar-class/
    calendar() {
      return this.$refs.calendar.control;
    }
  },
  methods: {
    async loadEvents(min, max) {
      // placeholder for an HTTP call
      await this.appointmentStore.getAppointments({min, max})
      const formattedAppointments = this.appointmentStore.appointments.map(appointment => ({
        id: appointment.id,
        start: moment.utc(appointment.startDateTime).format('YYYY-MM-DDTHH:mm:ss'),
        end: moment.utc(appointment.endDateTime).format('YYYY-MM-DDTHH:mm:ss'),
        html: `<strong style="display: block">${appointment.client.name}</strong><span style="font-size: 10px; display: block; margin-top: -3px">${moment.utc(appointment.startDateTime).format('HH:mm')} - ${moment.utc(appointment.endDateTime).format('HH:mm')}</span>${appointment.billed ? '<span class="tag">facturat</span>' : ''}`,
        barColor: appointment.client.color,
        barBackColor: appointment.client.color,
        tags: {
          clientName: appointment.client.name,
          billed: appointment.billed
        }
      }))
      this.calendar.update({events: formattedAppointments});
    },
    handlePrevDate () {
      this.config.startDate = moment.utc(this.config.startDate).subtract(1, this.$isMobile() ? 'day' : 'week').toDate()
      this.selectedDate = moment.utc(this.config.startDate)
      const startDate = moment.utc(this.config.startDate).locale('ro').startOf('week').subtract(1, 'day');
      const endDate = moment.utc(this.config.startDate).locale('ro').endOf('week').add(1, 'day');
      this.loadEvents(startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY'));
    },
    handleNextDate () {
      this.config.startDate = moment.utc(this.config.startDate).add(1, this.$isMobile() ? 'day' : 'week').toDate()
      this.selectedDate = moment.utc(this.config.startDate)
      const startDate = moment.utc(this.config.startDate).locale('ro').startOf('week').subtract(1, 'day');
      const endDate = moment.utc(this.config.startDate).locale('ro').endOf('week').add(1, 'day');
      this.loadEvents(startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY'));
    },
    handleChangeSelectedDate (value) {
      this.config.startDate = value.toDate()
    },
    // handleShowAddEventModal () {
    //   const event = new DayPilot.Event({
    //     id: DayPilot.guid(),
    //     start: '2022-08-11T19:15:00',
    //     end: '2022-08-11T20:15:00',
    //     text: 'success',
    //     barBackColor: '#7DD279',
    //     barColor: '#7DD279',
    //   })
    //   this.calendar.events.add(event)
    // },
    handleShowAddEventModal () {
      this.showAddEventModal = true
    },
    async handleAddEvent () {
      const startDateTime = `${this.addEventValues.date.format('YYYY-MM-DD')}T${this.addEventValues.startTime}:00`
      const endDateTime = `${this.addEventValues.date.format('YYYY-MM-DD')}T${this.addEventValues.endTime}:00`
      await this.appointmentStore.createAppointment({
        client: this.addEventValues.client,
        startDateTime,
        endDateTime
      })
      const event = new DayPilot.Event({
        id: this.appointmentStore.createdAppointment.id,
        start: startDateTime,
        end: endDateTime,
        text: `${this.appointmentStore.createdAppointment.client.name} \n ${this.addEventValues.startTime} - ${this.addEventValues.endTime}`,
        barColor: this.appointmentStore.createdAppointment.client.color,
        barBackColor: this.appointmentStore.createdAppointment.client.color,
      })
      this.calendar.events.add(event)
      this.showAddEventModal = false
      this.addEventValues = {
        client: null,
        date: null,
        startTime: null,
        endTime: null,
      }
    },
    filterOption (input, option) {
      const client = _.find(this.clientList, (client) => {
        return option.data.props.value === client.value
      })
      return client.label.toLowerCase().includes(input.toLowerCase())
    },
    async getClients () {
      await this.clientStore.getClients()
      this.clientList = this.clientStore.clients.map(client => ({value: client.id, label: client.name}))
    },
    handleClientSelectChange (value) {
      this.addEventValues.client = value
    },
    handleAfterModalClose () {
      this.addEventValues = {
        client: null,
        date: null,
        startTime: null,
        endTime: null,
      }
    },
    handleChangeWorkoutDate (value) {
      this.addEventValues.date = value
    },
    handleSetStartTime (value) {
      const split = value.split(':')
      let minutes = split[1]
      if (Number(minutes) >= 15) minutes = '30'
      else minutes = '00'
      this.addEventValues.startTime = `${split[0]}:${minutes}`
    },
    handleSetEndTime (value) {
      const split = value.split(':')
      let minutes = split[1]
      if (Number(minutes) >= 15) minutes = '30'
      else minutes = '00'
      this.addEventValues.endTime = `${split[0]}:${minutes}`
    },
    async updateEvent (eventData) {
      await this.appointmentStore.updateAppointment(eventData.id, { startDateTime: eventData.start.value, endDateTime: eventData.end.value, tags: eventData.tags })
      const startDate = moment.utc(this.config.startDate).locale('ro').startOf('week').subtract(1, 'day');
      const endDate = moment.utc(this.config.startDate).locale('ro').endOf('week').add(1, 'day');
      this.loadEvents(startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY'));
    },
    async deleteEvent (eventData) {
      await this.appointmentStore.deleteAppointment(eventData.id, eventData)
      const startDate = moment.utc(this.config.startDate).locale('ro').startOf('week').subtract(1, 'day');
      const endDate = moment.utc(this.config.startDate).locale('ro').endOf('week').add(1, 'day');
      this.loadEvents(startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY'));
    }
  },
  mounted() {
    const startDate = moment.utc().locale('ro').startOf('week').subtract(1, 'day');
    const endDate = moment.utc().locale('ro').endOf('week').add(1, 'day');
    this.loadEvents(startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY'));
    this.getClients()
  }
}
</script>

<style>
.calendar_default_cell_inner {
  background: #ffffff;
}
.tag {
  box-sizing: border-box;
  margin: 0 8px 0 0;
  color: #000000d9;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  height: auto;
  padding: 0 7px;
  font-size: 10px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all .3s;
}
</style>
